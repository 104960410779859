body {
  font-family: sans-serif;
}

.forkme {
  font-family: tahoma;
  font-size: 20px;
  position: fixed;
  top: 50px;
  right: -45px;
  display: block;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  background-color: #AA0000;
  color: white;
  padding: 4px 30px 4px 30px;
  z-index: 99;
  text-decoration: none;
}

#canvas {
  display: table;
  border-collapse: collapse;
}

div.row {
  display: table-row;
}

div.pixel {
  display: table-cell;
  border: 5px solid black;
  width: 30px;
  height: 30px;
}

div.colors {
  margin: 10px;
}

div.color {
  display: inline-block;
  width: 30px;
  height: 18px;
  border: solid 1px black;
  margin: 5px;
  cursor: pointer;
  vertical-align: middle;
}

button.active {
  background-color: orange;
}

.toolbar {
  margin: 5px -3px;
}

.toolbar button {
  margin: 0 3px;
}

button.connected {
  background-color: #008000;
}

#colorPicker {
  visibility: hidden;
}
